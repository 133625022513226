.fullpage {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    background-color: #eeeeee;
    //background-image: url('../../img/bg.jpg');
    background-attachment: fixed;
    background-position: center;
    background-size: 100%;
    background-size: cover;

    .btn{
        padding: 10px;
    }
}

.content {
    text-align: center;
    display: inline-block;
}

.search {
    @extend .shadow;
    @extend .border;
    @extend .radius;
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    position: relative;
    z-index: 999;
    margin-top: 10px;
}
.search__input{

  @extend .roboto;
  font-size: 15px;
  padding: 15px;
  text-align: center;
  width: 100%;
  border: none;
  box-sizing: border-box;
  outline: none

}
.search__button{

  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  height: 100%;
  font-size: 28px;
  background: transparent;
  padding-right: 15px;
  outline: none;
  cursor: pointer;
  color: $gray-d;

}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $gray-l;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $gray-l;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $gray-l;
}
:-moz-placeholder { /* Firefox 18- */
  color: $gray-l;
}
.logo{
  margin-bottom: 20px;
}

.like-filter{
  margin-top: 16px;
  font-size: 16px;
}

.filter{
  background-color: rgba(255,255,255,0.65);
  border-radius: 5px;
  @extend .shadow;
  @extend .border;
  margin-top: -10px;
  padding: 30px;
  box-sizing: border-box;
  color: $gray-d;
  font-size: 16px;
  position: relative;
  margin-bottom: 1em;

  .input-group-addon{
    @extend .border;
    border-left: 0;
  }

  table{
    width: 100%;

    td{
      text-align: left;
    }

  }

  select, input{
    width: 100%;
    @extend .border;
    @extend .radius;
    @extend .roboto;
    font-size: 16px;
    box-sizing: border-box;
    color: $gray-d;
  }

  input[type="checkbox"]{
    width: auto !important;
  }

  strong{
    text-shadow: 0px 0px 12px rgba(255, 255, 255, 1);
  }

  &--padding{
    padding: 10px 0px 5px 0px;
    display: block;
  }

  .ms-parent {
    width: 100% !important;
  }
}

.homepage{

  .mainLogo {
    max-width: 452px;
    margin: 30px auto 75px auto;
    img {
      width: 100%;
      height: auto;
    }
    position: relative;
  }

  padding-top: 10px;

  &__link {
    text-decoration: underline;
  }

}

.bottom-text {
  margin-top: 1em;
}

.ms-choice{

  border: 1px solid #848585 !important;
  border-radius: 5px;

}

.ms-drop{

  input{

    margin-right: 1em;

  }

}
