.searchBar{

  .input-group{

    margin: 20px 0px;

  }

}

.searchList{

  @media screen and (min-width: $screen-md-min) {
    .my-md-table {
      display: table;
      width: 100%;
      .my-md-row {
        display: table-row;
        .my-md-td {
          vertical-align: top;
          display: table-cell;
        }
      }
    } 
  }

  .btn{
      padding: 10px;
  }

  &__product{

    &.sold {
      background-color: #ccc;
    }

    position: relative;
    min-height: 150px;
    color: $gray-d;

    @extend .border;
    @extend .radius;
    background: #fff;
    overflow: hidden;
    margin-bottom: 10px;

    &__imageTd {
      @media screen and (min-width: $screen-sm-min) {
        width: 150px;
      }
    }

    &__image{

      max-width: 150px;
      width: 100%;

    }


    &__info{

      position: relative;
      padding: 10px;

      &__notAvailable {
        font-size: 1.5em;
        text-transform: uppercase;
        color: red;
        font-weight: bold;
        display: block;
        @media screen and (min-width: $screen-md-min) {
          display: inline;
          position: absolute;
          bottom: 10px;
          left:10px;
        }
      }

      h2{

        margin: 0px;
        font-size: 20px;
        margin-bottom: 5px;
        a{
          color: $gray-d;
          text-decoration: none;
        }

      }

      &__autor{

        color: $gray-l;

        a{

          color: $gray-l;

        }

      }

      &__master{

        font-size: 12px;

        @media screen and (min-width: $screen-sm-min) {
          position: absolute;
          bottom: 10px;
          left: 130;
        }

      }

      &__report{

        font-size: 12px;

        @media screen and (min-width: $screen-md-min) {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }

      }

    }


    &__vendor{

      &.sold {
        background-color: #ccc;
      }

      position: relative;
      padding: 10px;
      box-sizing: border-box;

      @media screen and (min-width: $screen-sm-min) {
        width: 250px;
        padding-bottom: 85px;
        &.highPrice {
          padding-bottom: 101px;
        }
        &.sold {
          padding-bottom: 175px;
        }
      }

      &__bottom{
        width: 100%;
        box-sizing: border-box;

        @media screen and (min-width: $screen-sm-min) {
          position: absolute;
          bottom: 0px;
          padding-right: 10px;
        }

        &__link{
          display: block;
          margin: 10px 10px 10px 0px;
        }

        &__price{

          &__soldLabel {
            font-size: 0.5em;
            text-align: left;
          }

          .sold & {
            color: #464646;
          }

          color: #004A1D;
          font-size: 2em;
          margin-bottom: 10px;
          text-align: center;

          @media screen and (min-width: $screen-sm-min) {
            margin-right: 10px;
            text-align: right;
          }

        }

      }

    }

  }

  &__watchExpression {
    margin: 20px;
  }

}
