$gray-d : #464646;
$gray-l : #848585;

.shadow{
  -webkit-box-shadow: 5px 5px 36px -9px rgba(0,0,0,0.5);
  -moz-box-shadow: 5px 5px 36px -9px rgba(0,0,0,0.5);
  box-shadow: 5px 5px 36px -9px rgba(0,0,0,0.5);
}
.border{
  border: 1px solid $gray-l;
}

.roboto{

  font-family: 'Roboto Slab', serif;

}

.radius{

  border-radius: 5px;

}

p{
  margin: 0.5em 0px;
}

ul.pagination{

}

$break-small: 320px;
$break-large: 1024px;

@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) { @content; }
  }
  @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) { @content; }
  }
}

body + .header {

  background-color: red;

}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {

    -webkit-appearance: none;
}

.footer{
  text-align: center;
  padding: 0.5em;
}

.fucking-eu-cookies {
  align-items:center;
  background:menu;
  color:graytext;
  display:flex;
  flex-wrap:nowrap;
  justify-content:center;
  padding:5px;
  position:relative;
  z-index:1000;
}
.fucking-eu-cookies, .fucking-eu-cookies span, .fucking-eu-cookies a {
  font-family:Arial, Helvetica, sans-serif;
  font-size:12px;
}
.fucking-eu-cookies span {
  padding-right:5px;
}
.fucking-eu-cookies a, .fucking-eu-cookies a:hover, .fucking-eu-cookies a:visited, .fucking-eu-cookies a:active, .fucking-eu-cookies a:focus {
  color:graytext;
  text-decoration:underline;
}
.fucking-eu-cookies button {
  cursor:pointer;
  flex-shrink:0;
}
