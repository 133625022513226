$brand-primary: #004A1D !default;

.btn{

    background-color: #ffffff;

}

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "main.scss";

.btn{

    @extend .border;
    @extend .radius;
    text-decoration: none;
    .fa{
      margin-right: 10px;
    }
}

html, body {
    font-family: 'Roboto Slab', serif;
    position: relative;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #efefef;
}

.header{
  padding: 5px;
  top: 0px;
  right: 0px;
  z-index: 999;
  background: #2b2b2b;
  width: 100%;

  .my-container {
    @media screen and (max-width: 320px) {
      padding-left: 0px;
      padding-right: 0px;
    }
    max-width: 1200px;
    .header__search {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .my-table {
      width: 100%;
      display: table;
      .my-row {
        display: table-row;
        .my-td {
          text-align: right;
          display: table-cell;
        }
      }
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    .my-container {
      .my-table {
        text-align: right;
        display: block;
        .my-row {
          display: inline;
          .my-td {
            display: inline;
          }
        }
      }
      .my-table-2 {
        position: relative;
        padding-bottom: 20px;
        width: 100%;
        display: table;
        .my-row-2 {
          display: table-row;
          .my-td-2 {
            &.logo {
              min-width: 268px;
            }
            &.searchContainer {
              width: 100%;
            }
            &.links {
              white-space: nowrap;
              position: absolute;
              bottom: 0px;
              right: 0px;
            }
            display: table-cell;
          }
        }
      }
    }
  }
  @media screen and (min-width: $screen-md-min) {
    .my-container {
      .my-table-2 {
        padding-bottom: 0px;
        .my-row-2 {
          .my-td-2 {
            &.links {
              position: static;
            }
          }
        }
      }
    } 
  }

  a{
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    padding: 10px;

    .fa{
      margin-right: 8px;
    }

    &:hover{
      text-decoration: underline;;
    }

  }

  &__logo{
    display: block;
    img {
      width: 100%;
      max-width: 268px;
      height: auto;
    }
  }

  &__search{

  }

  &__search{
      @extend .shadow;
      @extend .border;
      @extend .radius;
      background-color: #ffffff;
      width: 100%;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;

      &__input{

        @extend .roboto;
        padding: 5px;
        text-align: center;
        width: 100%;
        border: none;
        box-sizing: border-box;
        outline: none

      }
      &__button{

        position: absolute;
        top: 0px;
        right: 0px;
        border: none;
        height: 100%;
        font-size: 15px;
        background: transparent;
        padding-right: 10px;
        outline: none;
        cursor: pointer;
        color: $gray-d;

      }

  }

}

.main{

  //margin: 66px auto 0px auto;

}

a{
  color: $gray-d;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

@import "homepage.scss";
@import "search_list.scss";
